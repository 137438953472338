var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(! _vm.isModal)?_c('div',{staticClass:"level is-variable bd-klmn-columns is-1 is-marginless"},[_c('div',{staticClass:"level-left"},[_c('b-button',{staticClass:"filter-button is-hidden-mobile",attrs:{"size":"is-circle","icon-left":"filter-remove"},on:{"click":_vm.clearFilter}}),_c('div',{staticClass:"filter-tags"},_vm._l((_vm.filtered),function(value,index){return _c('b-tag',{key:index,attrs:{"type":"is-purble","closable":""},on:{"close":function($event){return _vm.removeFilter(value)}}},[_vm._v(" "+_vm._s(_vm.$t('filter.' + value))+" ")])}),1)],1),_c('div',{staticClass:"level-right"},[_vm._t("header-right")],2)]):_vm._e(),_c('b-table',{staticClass:"no-wrap cars-table",attrs:{"data":_vm.cars,"loading":_vm.isLoading,"current-page":_vm.pagination.page,"total":_vm.pagination.total,"per-page":_vm.pagination.perPage,"default-sort-direction":_vm.sort.defaultOrder,"default-sort":[_vm.sort.field, _vm.sort.order],"striped":"","sticky-header":"","paginated":"","hoverable":"","backend-sorting":"","backend-pagination":""},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-change":_vm.onPageChange,"sort":_vm.onSort}},[_c('b-table-column',{staticClass:"is-actions-cell",attrs:{"custom-key":"actions","sticky":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{staticClass:"button-purble btn-details",attrs:{"outlined":"","size":"is-small","icon-left":"arrow-right-bold","tag":"router-link","to":{ name: 'cars.edit', params: { id: props.row.id }}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Plaka","field":"plate","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{staticClass:"search-dropdown",attrs:{"aria-role":"menu","close-on-click":false}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"aria-role":"menu-item","focusable":false}},[_c('b-input',{attrs:{"placeholder":_vm.$t('plateSearch'),"size":"is-small","autocomplete":"off","type":"search"},model:{value:(_vm.filter.plate),callback:function ($$v) {_vm.$set(_vm.filter, "plate", $$v)},expression:"filter.plate"}})],1)],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-abbr-like"},[_vm._v(" "+_vm._s(props.row.plate)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('brand'),"field":"brand","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{staticClass:"search-dropdown",attrs:{"aria-role":"menu","close-on-click":false}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"aria-role":"menu-item","focusable":false}},[_c('b-input',{attrs:{"placeholder":_vm.$t('brandSearch'),"size":"is-small","autocomplete":"off","type":"search"},model:{value:(_vm.filter.brand),callback:function ($$v) {_vm.$set(_vm.filter, "brand", $$v)},expression:"filter.brand"}})],1)],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.brand)+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('model'),"field":"model","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{staticClass:"search-dropdown",attrs:{"aria-role":"menu","close-on-click":false}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"aria-role":"menu-item","focusable":false}},[_c('b-input',{attrs:{"placeholder":_vm.$t('modelSearch'),"size":"is-small","autocomplete":"off","type":"search"},model:{value:(_vm.filter.model),callback:function ($$v) {_vm.$set(_vm.filter, "model", $$v)},expression:"filter.model"}})],1)],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.model)+" ")]}}])}),_c('b-table-column',{staticClass:"is-actions-cell",attrs:{"label":_vm.$t('filter.status'),"custom-key":"actions","centered":"","sortable":"","field":"status"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{attrs:{"aria-role":"list"},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_vm._l((_vm.statusFilters),function(status,index){return _c('b-dropdown-item',{key:index,attrs:{"value":status.value,"aria-role":"listitem"}},[_c('span',[_vm._v(_vm._s(status.name))])])})],2),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"tag",class:props.row.ready ? 'is-success' : 'is-danger'},[_vm._v(" "+_vm._s(_vm.$t(("itemStatus." + (props.row.ready))))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('seats'),"field":"seats","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.seats)?_c('span',{staticClass:"tag is-abbr-like"},[_vm._v(" "+_vm._s(props.row.seats)+" ")]):_vm._e()]}}])}),_c('b-table-column',{staticClass:"is-actions-cell",attrs:{"label":_vm.$t('filter.owner'),"custom-key":"actions","centered":"","sortable":"","field":"owner"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('b-dropdown',{attrs:{"aria-role":"list"},model:{value:(_vm.filter.owner),callback:function ($$v) {_vm.$set(_vm.filter, "owner", $$v)},expression:"filter.owner"}},[_c('b-icon',{staticClass:"search-icon pointer",attrs:{"slot":"trigger","icon":"magnify","custom-size":"mdi-20px"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"value":"Kurum","aria-role":"listitem"}},[_c('span',[_vm._v("Kurum")])]),_c('b-dropdown-item',{attrs:{"value":"Diğer","aria-role":"listitem"}},[_c('span',[_vm._v("Diğer")])])],1),_vm._v(" "+_vm._s(column.label)+" ")]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.owner)+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('totalVoyage'),"field":"total_voyage","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-abbr-like"},[_vm._v(" "+_vm._s(props.row.total_voyage)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('totalDistance'),"field":"total_distance","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-abbr-like"},[_vm._v(" "+_vm._s(props.row.total_distance)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('totalTime'),"field":"total_time","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-abbr-like"},[_vm._v(" "+_vm._s(props.row.total_time_min)+" ")])]}}])}),_c('template',{slot:"bottom-left"},[(_vm.pagination.total > 0)?_c('div',{staticClass:"has-text-left subtitle help"},[_vm._v(" "+_vm._s(_vm.$t('paginationShowing', [_vm.pagination.total.toLocaleString(), ((_vm.pagination.page * _vm.pagination.perPage) - _vm.pagination.perPage) + 1, ((_vm.pagination.page * _vm.pagination.perPage) > _vm.pagination.total ? _vm.pagination.total : (_vm.pagination.page * _vm.pagination.perPage)) ]))+" ")]):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }